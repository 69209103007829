<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img src="@/assets/images/logo-smart-378.svg" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" xl="9" class="d-none d-lg-flex align-items-center bg-login"></b-col>
      <!-- /Left Text-->

      <!-- Login-->

      <b-col v-if="!userEmail || !password || !rememberMe" lg="4" xl="3"
        class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-0 font-weight-bold" title-tag="h2">
            Benvenuto su
            <br />
            <b class="text-primary">Smart 378</b>
          </b-card-title>
          <b-card-text class="mb-4">Utilizza le tue credenziali per accedere.</b-card-text>

          <small v-if="login_error != ''" class="text-danger">{{ login_error }}</small>

          <!-- form -->
          <validation-observer ref="loginForm">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
                  <b-form-input id="login-email" v-model="userEmail" :state="errors.length > 0 ? false : null"
                    name="login-email" placeholder="mario.rossi@gmail.com" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null"
                      class="form-control-merge" :type="passwordFieldType" name="login-password"
                      placeholder="Password" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                  <div class="text-right">
                    <b-link :to="null" v-b-modal.modal-recovery>
                      <small>Password Smarrita?</small>
                    </b-link>
                  </div>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">Ricordami</b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button class="mb-5" variant="primary" block @click="login">Accedi</b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Non hai un account?</span>
            <b-link :to="{ name: 'register' }">
              <b-button class="mt-1" block variant="outline-primary">&nbsp;Registrati</b-button>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>

      <!-- Login Remember -->

      <b-col v-else lg="4" xl="3" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-0 font-weight-bold" title-tag="h2">
            Bentornato
            <br />
            <b class="text-primary">{{ user.name }}!</b>
          </b-card-title>

          <small v-if="login_error != ''" class="text-danger">{{ login_error }}</small>

          <!-- form -->
          <validation-observer ref="loginForm">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">

              <!-- submit buttons -->
              <b-button class="mb-2" variant="primary" block @click="login">Entra in Smart 378</b-button>
            </b-form>
          </validation-observer>

          <small class="text-center">Non sei tu? <b-link :to="null" @click="deleteData">
              <small>Esci ed effettua l'accesso</small>
            </b-link></small>

        </b-col>
      </b-col>

    </b-row>


    <!-- Modal -->

    <b-modal id="modal-recovery" centered title="Password Smarrita?" cancel-title="Annulla"
      @ok.prevent="recoveryMailValidation">
      <validation-observer ref="recoveryMailValidation">
        <b-form-group label="Inserisci Indirizzo E-mail di recupero">
          <validation-provider #default="{ errors }" name="E-mail di recupero" rules="email|required">
            <b-form-input v-model="recovery_email">

            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
    </b-modal>

    <b-modal id="modal-recovery-success" centered title="Richiesta Completata" ok-only :ok-disabled="loading">
      <p>Se l'indirizzo e-mail specificato dovesse corrispondere ad un account esistente, riceverai una mail</p>
    </b-modal>

  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip, BModal,
} from 'bootstrap-vue'

import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { Requests } from '@/api/requests.js'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BModal
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user: {
        name: ''
      },
      status: '',
      password: '',
      userEmail: '',
      recovery_email: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      login_error: '',

      // validation rules
      required,
      email,

      //Helper

      loading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },

  created() {
    localize('it')

    this.userEmail = localStorage.getItem('user_mail');
    this.password = localStorage.getItem('user_secret');
    this.rememberMe = localStorage.getItem('remember_me');

    this.getLoggedUser();

  },

  methods: {

    deleteData() {
      this.userEmail = '';
      this.password = '';
      this.rememberMe = null;

      localStorage.removeItem('user');
      localStorage.removeItem('user_mail');
      localStorage.removeItem('user_secret');
      localStorage.removeItem('remember_me');
    },

    getLoggedUser() {
      if (localStorage.getItem('remember_me')) {
        const user_data = JSON.parse(localStorage.getItem('user'))
        console.log(user_data);
        this.user.name = user_data.user_name
      }
    },

    login() {
      this.login_error = '';

      let data = new FormData();

      data.append('email', this.userEmail)
      data.append('password', this.password)

      this.$refs.loginForm.validate().then(async success => {
        if (success) {
          try {
            const response = await Requests.login(data)
            if (response.login.success == 'false') { this.login_error = response.login.feedback; return; }
            localStorage.setItem('access_token', response.login.token)
            if (this.status) { localStorage.setItem('remember_me', 1); localStorage.setItem('user_mail', this.userEmail); localStorage.setItem('user_secret', this.password) }
            const userData = await Requests.getUser();
            localStorage.setItem('user', JSON.stringify(userData.me.payload));
            this.$router.push('/dashboard')
          } catch (err) {
            console.log(err)
          }


        }
      })
    },

    async passwordRecovery() {

      this.loading = true;

      let data = new FormData()

      data.append('user_email', this.recovery_email)

      try {
        await Requests.passwordRecovery(data)
        this.recovery_email = ''
      } catch (err) {
        console.log(err)
      }

      this.loading = false;
    },

    recoveryMailValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.recoveryMailValidation.validate().then((success) => {
          if (success) {

            this.passwordRecovery();
            this.$bvModal.hide('modal-recovery')
            this.$bvModal.show('modal-recovery-success')
            resolve(true);
          } else {

            reject();
          }
        });
      });
    }
  },
}
</script>

<style scoped>
.bg-login {
  background-image: url("../assets/images/bg-login.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
